<template>
  <form id="frm_action">
    <div class="p-formgrid">
      <div class="">
        <!-- <div class="p-field p-grid" v-if="(!$commonFuction.isEmpty(code))">
              <label class="p-col-fixed txt-right">Mã chủ thú cưng (*)</label>
              <div class="p-col">
                <InputText disabled="disabled" v-model="code"   class="p-col-width" />
              </div>
            </div> -->
        <div class="">
          <label class="txt-right required">Lý do</label>
          <!-- <div class="p-col"> -->
          <Textarea
            v-model="reason.description"
            :autoResize="true"
            rows="1"
            class="text-box input-text-box"
            placeholder="Nhập"
          />
          <input class="input_tmp_validator" id="reason" />
          <!-- </div> -->
        </div>
        <label class="txt-right required pt-3">Phân loại</label>
        <!-- <div class="p-col"> -->
        <Dropdown
          @change="change_type"
          v-model="reason.type"
          :options="reason_list"
          name="type"
          optionLabel="label"
          optionValue="value"
          placeholder="Chọn"
          class="text-box"
        />
        <input class="input_tmp_validator" id="type" />
        <!-- <select
          name="type"
          id="type"
          type="string"
          v-model="reason.type"
          class="text-box"
        >
          <option value="" disabled select hidden>Chọn</option>
          <option value="1">Lý do trả hàng</option>
          <option value="2">Lý do giao hàng không thành công</option>
          <option value="3">Lý do huỷ đơn</option>
        </select> -->
        <!-- </div> -->
        <!-- <div class="p-field p-grid"> -->
        <div class="flex justify-content-center pt-3">
          <Checkbox v-model="reason.company_pay" :binary="true" />Lê Mây chịu
          phí vận chuyển
        </div>
        <div class="flex justify-content-center pt-3">
          <Checkbox :disabled="disable_cancel_at_warehouse" v-model="reason.cancel_at_warehouse" :binary="true" />Huỷ đơn nhập lại kho
        </div>
        <!-- </div> -->
        <!-- <div class="p-field p-grid"> -->
        <div class="flex justify-content-center pt-3">
          <Checkbox v-model="reason.active" :binary="true" />Hiển thị
        </div>
        <!-- </div> -->
      </div>

      <Toolbar class="fixed-bottom-toolbar">
        <template slot="right">
          <Button
            :label="$constants.TEXT_BUTTON.BACK"
            @click="backToList()"
            class="p-button-warning return-style-button"
          />
          <Button
            v-if="mode == 'edit'"
            :label="$constants.TEXT_BUTTON.EDIT"
            @click="saveData()"
            class="p-button-success main-style-button"
          />
          <Button
            v-else
            :label="$constants.TEXT_BUTTON.ADD"
            @click="saveData()"
            class="p-button-success main-style-button"
          />
        </template>
      </Toolbar>
    </div>
  </form>
</template>

<script>
import DataServices from "@/core/DataServices";
import gql from "graphql-tag";

export default {
  props: {
    mode: String,
    model: Object,
    users: Array,
  },
  data() {
    return {
      reason: {
        description: "",
        type: "",
        company_pay: false,
        active: true,
        cancel_at_warehouse: false,
      },
      reason_list: [
        { label: "Lý do trả hàng", value: 1 },
        { label: "Lý do giao hàng không thành công", value: 2 },
        { label: "Lý do huỷ đơn", value: 3 },
      ],
      disable_cancel_at_warehouse:true,
      code: "",
      validationErrors: {},
      formMode: this.mode,
      images: [],
      imagesDeletes: [],
      maxFileSize: 1000000, // 1MB
      maxFiles: 10,
      account_exist: false,
      firebaseID: null,
      userID: null,
      list_group_role: [],
      saving: false,
      addressData: {
        street_number: "251",
        route: "Quang Trung",
        administrative_area_level_1: "Thành phố Hồ Chí Minh",
        country: "Việt Nam",
        latitude: 10.8286588,
        longitude: 106.6732573,
      },
      placeResultData: {
        address_components: [
          { long_name: "251", short_name: "251", types: ["street_number"] },
          {
            long_name: "Quang Trung",
            short_name: "Quang Trung",
            types: ["route"],
          },
          {
            long_name: "Gò Vấp",
            short_name: "Gò Vấp",
            types: ["administrative_area_level_2", "political"],
          },
          {
            long_name: "Thành phố Hồ Chí Minh",
            short_name: "Thành phố Hồ Chí Minh",
            types: ["administrative_area_level_1", "political"],
          },
          {
            long_name: "Việt Nam",
            short_name: "VN",
            types: ["country", "political"],
          },
        ],
        adr_address:
          '<span class="street-address">251 Quang Trung</span>, <span class="extended-address">Phường 10</span>, <span class="locality">Gò Vấp</span>, <span class="region">Thành phố Hồ Chí Minh</span>, <span class="country-name">Việt Nam</span>',
        formatted_address:
          "251 Quang Trung, Phường 10, Gò Vấp, Thành phố Hồ Chí Minh, Việt Nam",
        geometry: {
          location: { lat: 10.8286588, lng: 106.6732573 },
          viewport: {
            south: 10.8273459697085,
            west: 106.6719226697085,
            north: 10.8300439302915,
            east: 106.6746206302915,
          },
        },
        icon: "https://maps.gstatic.com/mapfiles/place_api/icons/v1/png_71/geocode-71.png",
        name: "251 Quang Trung",
        place_id: "ChIJ8-A91QMpdTERWj0x_fnFL2Y",
        plus_code: {
          compound_code: "RMHF+F8 Gò Vấp, Thành phố Hồ Chí Minh, Việt Nam",
          global_code: "7P28RMHF+F8",
        },
        reference: "ChIJ8-A91QMpdTERWj0x_fnFL2Y",
        types: ["street_address"],
        url: "https://maps.google.com/?q=251+Quang+Trung,+Ph%C6%B0%E1%BB%9Dng+10,+G%C3%B2+V%E1%BA%A5p,+Th%C3%A0nh+ph%E1%BB%91+H%E1%BB%93+Ch%C3%AD+Minh,+Vi%E1%BB%87t+Nam&ftid=0x31752903d53de0f3:0x662fc5f9fd313d5a",
        utc_offset: 420,
        html_attributions: [],
        utc_offset_minutes: 420,
      },
    };
  },
  async mounted() {
    // var that = this;
    if (this.model !== undefined && Object.keys(this.model).length > 0) {
      console.log("this.modelthis.model:this.model", this.model);
      this.reason.description = this.model.name;
      this.reason.type = this.model.type;
      this.reason.company_pay = this.model.company_pay;
      this.reason.active = this.model.active;
      this.reason.cancel_at_warehouse = this.model.cancel_at_warehouse;
      console.log("shibaaaaaaaaaaaaaa", this.reason.active);
      var dataGroupUser = await this.getGroupUser(this.model.id);
      if (dataGroupUser && dataGroupUser.length) {
        this.model.group_id = dataGroupUser[0].group_id;
      }
      this.user = this.$commonFuction.convertJsonObject(this.model);
      this.$CoreService.getAccountByUid(this.model.id).then((res) => {
        console.log("getAccount", res);
        if (res && res.length) {
          this.user.user_id = res[0].uid;
        }
      });
      this.load_reason(this.reason.type)
    }
    this.listGroupRole().then((res) => {
      console.log("resres", res);
      this.list_group_role = res;
    });
  },
  methods: {
    async change_type() {
      console.log("reason.typereason.type:",this.reason.type)
      this.load_reason(this.reason.type)
    },
    async load_reason(type) {
      if(type==3){
        this.disable_cancel_at_warehouse = false
      }else {
        this.disable_cancel_at_warehouse = true
        this.reason.cancel_at_warehouse = false;//set data false
      }
    },
    async listGroupRole() {
      var where = {
        active: { _eq: true },
        deleted: { _eq: false },
      };
      var order_by = { id: "desc" };
      var listData = await this.$CoreService.getListData(
        "group",
        where,
        null,
        null,
        order_by
      );
      return listData;
    },
    async getGroupUser(uid) {
      var table_cer = "group_user";
      var where = { uid: { _eq: uid } };
      var gqLQueryList = await DataServices.getList(table_cer);
      var resData = await this.$apollo.mutate({
        mutation: gqLQueryList,
        variables: {
          where_key: where,
        },
      });
      var listData = resData.data[table_cer];
      return listData;
    },
    /*async getAccount(uid) {
        var table_cer = 'accounts';
        var where = {uid: {_eq: uid}};
        var gqLQueryList =await DataServices.getList(table_cer);
        var resData = await this.$apollo.mutate({
          mutation: gqLQueryList,
          variables: {
            where_key:where ,
          }
        });
        var listData = resData.data[table_cer];
        return listData;
      },*/
    getAddressData: function (addressData, placeResultData, id) {
      this.addressData = addressData;
      this.placeResultData = placeResultData;
      console.log(JSON.stringify(addressData));
      console.log(JSON.stringify(placeResultData));
      console.log(id);
    },
    backToList() {
      this.$emit("back");
    },
    async checkEmailAdminExist(email, model) {
      //check tồn tại sdt
      if (!this.$commonFuction.isEmpty(email)) {
        var where = {
          account: { _eq: email },
          type: { _eq: "EMAIL" },
          deleted: { _eq: false },
        };
        if (model !== undefined)
          where = Object.assign(where, { uid: { _neq: model.id } });

        var { data } = await this.$apollo.query({
          query: DataServices.getList("accounts", {
            fields: "id, account, type",
          }),
          variables: {
            where_key: where,
          },
          fetchPolicy: "network-only",
        });

        // console.log(data)
        return data && data[Object.keys(data)].length > 0;
      }
    },
    async validateData() {
      this.validationErrors = {};

      let reason_add = document.getElementById("reason");
      if (
        this.reason.description == undefined ||
        this.reason.description == null ||
        this.reason.description == ""
      ) {
        reason_add.setCustomValidity("Lý do không được để trống!");
      } else if (this.reason.description.length > 255) {
        reason_add.setCustomValidity("Lý do không được quá 255 ký tự!");
      } else {
        reason_add.setCustomValidity("");
      }

      let reason_type = document.getElementById("type");
      if (
        this.reason.type == undefined ||
        this.reason.type == null ||
        this.reason.type == ""
      ) {
        reason_type.setCustomValidity("Phân loại không được để trống!");
      } else {
        reason_type.setCustomValidity("");
      }
      var inpObj = document.getElementById("frm_action");
      if (!inpObj.checkValidity()) {
        console.log("test=====================");
        inpObj.reportValidity();
        return true;
      }
    },
    convertPhone(numberPhone) {
      var phone = numberPhone;
      if (numberPhone[0] === "0") {
        phone = phone.substring(1);
      }
      phone = phone.replace(" ", "");
      phone = "+84" + phone;
      return phone;
    },
    async getUserByPhoneNumber(phoneNumber) {
      // var phone = phoneNumber;
      // if (phoneNumber[0] === "0") {
      //   phone = phone.substring(1);
      // }
      // phone = phone.replace(' ', '');
      // phone = "+84" + phone;
      let query = `mutation MyMutation {
          checkPhoneNumber(phone: "${this.convertPhone(phoneNumber)}") {
            info
          }
        }`;
      await this.$apollo
        .mutate({
          mutation: gql(query),
        })
        .then(async (res) => {
          // console.log('=========> checkPhoneNumber: ', res)
          if (res) {
            this.firebaseID = res.data.checkPhoneNumber.info.uid;
            await this.$apollo
              .query({
                query: DataServices.getList("users", { fields: "id" }),
                variables: {
                  where_key: {
                    phone: { _eq: this.phone },
                    deleted: { _eq: false },
                  },
                },
                fetchPolicy: "network-only",
              })
              .then((res) => {
                if (res && res.data.users.length > 0)
                  this.userID = res.data.users[0].id;
                else this.userID = null;
                // console.log(this.userID)
              })
              .catch((error) => {
                console.log("ERROR: ", error.message);
              });
          }
        })
        .catch((error) => {
          console.log("ERROR: ", error.message);
          // CHƯA CÓ FIREBASE ID
          this.firebaseID = null;
          this.userID = null;
        });
    },
    async checkPhoneExist() {
      if (this.phone !== undefined && this.phone !== "") {
        // var phone = this.phone;
        // if (this.phone[0] === "0") {
        //   phone = phone.substring(1);
        // }
        // phone = phone.replace(' ', '');
        // phone = "+84" + phone;

        var where = {
          account: { _eq: this.phone },
          type: { _eq: "PHONE" },
          deleted: { _eq: false },
        };
        if (this.model !== undefined)
          where = Object.assign(where, { id: { _neq: this.model.id } });
        var { data } = await this.$apollo.query({
          query: DataServices.getList("accounts", {
            fields: "id, account, type",
          }),
          variables: {
            where_key: where,
          },
          fetchPolicy: "network-only",
        });
        // console.log(data)
        return data && data[Object.keys(data)].length > 0;
      }
    },
    async saveData() {
      /* if(this.saving == true){
           return;
         }
         this.saving = true;*/
      this.$commonFuction.is_loading(true);
      if (await this.validateData()) {
        this.$commonFuction.is_loading(false);
        return;
      }
      if (Object.entries(this.validationErrors).length !== 0)
        this.$commonFuction.is_loading(false);

      // if (!this.account_exist) {
      var params = {
        name: this.reason.description,
        type: this.reason.type,
        company_pay: this.reason.company_pay,
        active: this.reason.active,
        cancel_at_warehouse: this.reason.cancel_at_warehouse,
        // firebaseID: this.firebaseID,
        // userID: this.userID,
        // addressData:this.addressData,
        // placeResultData:this.placeResultData
        /*listCategory : this.selectedCategory,
            addCategory : this.addCategory,
            delCategory : this.delCategory,*/
      };
      console.log("saveData", params);
      this.$emit("save", params);
      // }
    },
    isImage(file) {
      return /^image\//.test(file.type);
    },
    async onFileSelect(event) {
      let files = event.target.files || event.dataTransfer.files;
      if (files.length > 0) {
        let acceptTags = [
          "jpeg",
          "jpg",
          "gif",
          "png",
          "JPEG",
          "JPG",
          "PNG",
          "GIF",
        ];
        for (let i = 0; i < files.length; i++) {
          if (!acceptTags.includes(files[i].name.split(".").pop())) {
            alert(
              "Please upload the image file in the following format: jpeg, jpg, gif, png"
            );
            return;
          }
        }
        if (files.length + this.images.length > this.maxFiles) {
          alert("Hình ảnh quá số lượng tối đa là " + this.maxFiles + " hình");
        } else {
          var arrTemp = [],
            arrFiles = [];
          for (let file of files) {
            if (this.isImage(file) && file.size <= this.maxFileSize) {
              file.source = file;
              file.objectURL = window.URL.createObjectURL(file);
              arrTemp.push(file);
            }
            if (file.size > this.maxFileSize) arrFiles.push(file);
          }

          if (arrFiles.length > 0) {
            alert("Hình ảnh của bạn đã quá dung lượng 1MB");
          } else {
            if (this.images.length > 0 && this.images[0].file === "") {
              this.imagesDeletes.push(this.images[0]);
            }
            this.images = [];
            this.images = this.images.concat.apply(this.images, arrTemp);
            // console.log(this.images)
            await this.validateData();
          }
        }
      }
    },
    onFileClear(index) {
      let img = this.images[index];
      this.images.splice(index, 1);
      if (img.file === "") this.imagesDeletes.push(img);
    },
  },
  /*apollo: {
      category: {
        query() {
          return DataServices.getList('category', {"fields" : "id name"});
        },
        update(data) {
          if(data.category){
            this.categories = data.category
          }
        },
        fetchPolicy: "network-only"
      },
    }*/
};
</script>

<style lang="scss">
.p-multiselect {
  width: 450px;
}
.p-multiselect-label:not(.p-placeholder) {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}
.category-item-value {
  padding: 0.25rem 0.5rem;
  border-radius: 3px;
  display: inline-flex;
  margin-right: 0.2rem;
  background-color: var(--primary-color);
  color: var(--primary-color-text);
}
.p-multiselect-label-container {
  height: 40px;
}

label {
  font-weight: bold;
}
.required:after {
  content: " *";
  color: red;
}
.vs__dropdown-option.vs__dropdown-option--selected,
.p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
  background-color: #c40380 !important;
  color: #ffffff;
}
.p-checkbox {
  margin-right: 1rem !important;
}
// .view-action {
//   .toolbar-top {
//     background: #fff;
//     padding: 0;
//     border: 0 none;
//     margin-bottom: 16px;
//     padding-bottom: 10px;
//     border-bottom: 1px solid #eeeeee;
//     .manager-title {
//       border: 0 none;
//       padding: 0;
//       margin: 0;
//     }
//     .p-toolbar-group-right {
//       .p-button {
//         margin-right: 8px;
//         width: auto;
//         &:last-child {
//           margin-right: 0;
//         }
//       }
//     }
//   }
// }
</style>
